import React from "react";
import { Link } from "react-router-dom";
function ServiceTen() {
  return (
    <div>
      {/* our service area start */}
      <div className="rts-service-area rts-section-gapTop pb--200 service-two-bg bg_image">
        <div className="container">
          <div className="row g-5 service padding-controler">
            {/* single service area */}
            <div className="col-xl-4 col-md-6 col-sm-12 col-12 pb--140 pb_md--100">
              <div className="service-two-inner">
                <Link to={"/hr-staffing"} className="thumbnail">
                  <img
                    src="assets/images/service/02.jpg"
                    alt="Business_image"
                  />
                </Link>
                <div className="body-content">
                  <div className="hidden-area">
                    <h5 className="title">HR Staffing</h5>
                    <p className="dsic">
                      Ensuring an organization is adequately staffed with the
                      right talent to meet its operational and strategic goals.
                    </p>
                    <Link
                      className="rts-read-more-two color-primary"
                      to={"/hr-staffing"}
                    >
                      Read More
                      <i className="far fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* single service area end*/}
            {/* single service area */}
            <div className="col-xl-4 col-md-6 col-sm-12 col-12 pb--140 pb_md--100">
              <div className="service-two-inner">
                <Link to={"/it-resource"} className="thumbnail two">
                  <img
                    src="assets/images/service/03.jpg"
                    alt="Business_image"
                  />
                </Link>
                <div className="body-content">
                  <div className="hidden-area">
                    <h5 className="title">IT Resource Management</h5>
                    <p className="dsic">
                      A trusted partner for IT staffing and resource management.
                      Specializing in delivering top-tier IT talent to meet
                      dynamic business needs.
                    </p>
                    <Link
                      className="rts-read-more-two color-primary"
                      to={"/it-resource"}
                    >
                      Read More
                      <i className="far fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* single service area end*/}
            {/* single service area */}
            <div className="col-xl-4 col-md-6 col-sm-12 col-12 pb--140 pb_md--100">
              <div className="service-two-inner">
                <Link to={"/it-services"} className="thumbnail three">
                  <img
                    src="assets/images/service/18_1.jpg"
                    alt="Business_image"
                  />
                </Link>
                <div className="body-content">
                  <div className="hidden-area">
                    <h5 className="title">Managed IT Services</h5>
                    <p className="dsic">
                      A proactive approach to IT management where experts handle
                      your technology needs, ensuring uptime, security, and
                      scalability.
                    </p>
                    <Link
                      className="rts-read-more-two color-primary"
                      to={"/it-services"}
                    >
                      Read More
                      <i className="far fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* single service area end*/}
          </div>
        </div>
      </div>
      {/* our service area end */}
    </div>
  );
}

export default ServiceTen;
