import React, { useState } from "react";
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "./Breadcrumb";

import { Link } from "react-router-dom";
import FooterOne from "../components/footer/FooterOne";

import { Tabs, Tab, TabContent } from "react-bootstrap";

function Careers() {
  const breadcrumbs = [{ label: "Home", link: "/" }, { label: "Careers" }];
  const [activeKey, setActiveKey] = useState("home");

  return (
    <div className="">
      <HeaderOne />
      <Breadcrumb title="Careers" breadcrumbs={breadcrumbs} />

      {/* rts pricing area start */}
      <div className="rts-pricing-area rts-section-gap">
        <div className="container"></div>
      </div>
      {/* rts pricing area end */}

      <FooterOne />
    </div>
  );
}

export default Careers;
