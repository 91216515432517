import React from "react";
import { Link } from "react-router-dom";
function ServiceOne() {
  return (
    <div>
      <>
        {/* rts service post area  Start*/}
        <div className="rts-service-area rts-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="rts-title-area service text-center">
                  <p className="pre-title">Our Services</p>
                  <h2 className="title">High Quality Services</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid service-main plr--120-service mt--50 plr_md--0 pl_sm--0 pr_sm--0">
            <div className="background-service row">
              {/* start single Service */}
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="service-one-inner one">
                  <div className="thumbnail">
                    <img
                      src="assets/images/service/icon/01.svg"
                      alt="finbiz_service"
                    />
                  </div>
                  <div className="service-details">
                    <Link to={"/it-services"}>
                      <h5 className="title">Business Planning</h5>
                    </Link>
                    <p className="disc">
                      Accelerate business growth by enabling scalable
                      infrastructure, technical strategy and innovation
                    </p>
                    <Link
                      className="rts-read-more btn-primary"
                      to={"/it-services"}
                    >
                      <i className="far fa-arrow-right" />
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
              {/* end single Services */}
              {/* start single Service */}
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="service-one-inner two">
                  <div className="thumbnail">
                    <img
                      src="assets/images/service/icon/02.svg"
                      alt="finbiz_service"
                    />
                  </div>
                  <div className="service-details">
                    <Link to={"/it-resource"}>
                      <h5 className="title">IT Resource Management</h5>
                    </Link>
                    <p className="disc">
                      A trusted partner for IT staffing and resource management.
                      Specializing in delivering top-tier IT talent to meet
                      dynamic business needs.
                    </p>
                    <Link
                      className="rts-read-more btn-primary"
                      to={"/it-resource"}
                    >
                      <i className="far fa-arrow-right" />
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
              {/* end single Services */}
              {/* start single Service */}
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="service-one-inner three">
                  <div className="thumbnail">
                    <img
                      src="assets/images/service/icon/03.svg"
                      alt="finbiz_service"
                    />
                  </div>
                  <div className="service-details">
                    <Link to={"/it-services"}>
                      <h5 className="title">Strategy &amp; Planning</h5>
                    </Link>
                    <p className="disc">
                      We plan and implement the most suitable technologies
                      according to your project scope, timelines, and lifecycle
                      and frame KPIs to assess technological performance
                    </p>
                    <Link
                      className="rts-read-more btn-primary"
                      to={"/it-services"}
                    >
                      <i className="far fa-arrow-right" />
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
              {/* end single Services */}
              {/* start single Service */}
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="service-one-inner four">
                  <div className="thumbnail">
                    <img
                      src="assets/images/service/icon/04.svg"
                      alt="finbiz_service"
                    />
                  </div>
                  <div className="service-details">
                    <Link to={"/it-services"}>
                      <h5 className="title">Managed IT Services</h5>
                    </Link>
                    <p className="disc">
                      A proactive approach to IT management where experts handle
                      your technology needs, ensuring uptime, security, and
                      scalability.
                    </p>
                    <Link
                      className="rts-read-more btn-primary"
                      to={"/it-services"}
                    >
                      <i className="far fa-arrow-right" />
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
              {/* end single Services */}
              {/* start single Service */}
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="service-one-inner five">
                  <div className="thumbnail">
                    <img
                      src="assets/images/service/icon/05.svg"
                      alt="finbiz_service"
                    />
                  </div>
                  <div className="service-details">
                    <Link to={"/hr-staffing"}>
                      <h5 className="title">HR Staffing</h5>
                    </Link>
                    <p className="disc">
                      Ensuring an organization is adequately staffed with the
                      right talent to meet its operational and strategic goals.
                    </p>
                    <Link
                      className="rts-read-more btn-primary"
                      to={"/hr-staffing"}
                    >
                      <i className="far fa-arrow-right" />
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
              {/* end single Services */}
              {/* start single Service */}
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="service-one-inner six">
                  <div className="thumbnail">
                    <img
                      src="assets/images/service/icon/06.svg"
                      alt="finbiz_service"
                    />
                  </div>
                  <div className="service-details">
                    <Link to={"/it-services"}>
                      <h5 className="title">Consultancy &amp; Advice</h5>
                    </Link>
                    <p className="disc">
                      Our strategic team-building helps align your technology
                      initiatives with your business objectives appropriately
                    </p>
                    <Link
                      className="rts-read-more btn-primary"
                      to={"/it-services"}
                    >
                      <i className="far fa-arrow-right" />
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
              {/* end single Services */}
            </div>
            <div className="row">
              <div className="cta-one-bg col-12">
                <div className="cta-one-inner">
                  <div className="cta-left">
                    <h3 className="title">
                      Let’s discuss about how we can help make your business
                      better
                    </h3>
                  </div>
                  <div className="cta-right">
                    <Link className="rts-btn btn-white" href="appoinment.html">
                      Lets Work Together
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* rts service post area ENd */}
      </>
    </div>
  );
}

export default ServiceOne;
