import React from "react";
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "./Breadcrumb";
import { Link } from "react-router-dom";

import FooterOne from "../components/footer/FooterOne";

function HRStaffing() {
  const breadcrumbs = [{ label: "Home", link: "/" }, { label: "HR Staffing" }];
  return (
    <div className="">
      <HeaderOne />
      <Breadcrumb title="HR Staffing" breadcrumbs={breadcrumbs} />

      {/* start service details area */}
      <div className="rts-service-details-area rts-section-gap">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-md-12 col-sm-12 col-12">
              {/* service details left area start */}
              <div className="service-detials-step-1">
                <div className="thumbnail">
                  <img src="assets/images/service/01.jpg" alt="business-area" />
                </div>
                <h4 className="title">HR Staffing Services</h4>
                <p className="disc">
                  As HR experts, we provide personalized HR staffing solutions
                  to meet specific goals of the business throughout different
                  sectors. Our services help in comprehensively acquiring,
                  selecting, and serving your clients with enough skills for
                  your firm to execute its goals. With exploitation in human
                  resources management, we assist you in acquiring a pool of
                  employees that suit the position within the framework and
                  objectives of your company.
                </p>
                <p className="disc">
                  HR perceptive processes using professional tools and models in
                  a non-traditional style. Present fully functional application
                  systems. Streamline your HR processes to ensure competitive
                  advantage
                </p>
                <div className="row g-5 mt--30 mb--40">
                  <div className="col-lg-6">
                    {/* single service details card */}
                    <div className="service-details-card">
                      <div className="thumbnail">
                        <img
                          src="assets/images/service/icon/09.svg"
                          alt=""
                          className="icon"
                        />
                      </div>
                      <div className="details">
                        <h6 className="title">Seamless Recruitment Process</h6>
                        <p className="disc">
                          Utilize prioritized recruitment models to meet
                          workplace demands more effectively.
                        </p>
                      </div>
                    </div>
                    {/* single service details card End */}
                  </div>
                  <div className="col-lg-6">
                    {/* single service details card */}
                    <div className="service-details-card">
                      <div className="thumbnail">
                        <img
                          src="assets/images/service/icon/10.svg"
                          alt=""
                          className="icon"
                        />
                      </div>
                      <div className="details">
                        <h6 className="title">Strategic Workforce Planning</h6>
                        <p className="disc">
                          Enhance team performance by reinforcing operations
                          across other divisions.
                        </p>
                      </div>
                    </div>
                    {/* single service details card End */}
                  </div>
                  <div className="col-lg-6">
                    {/* single service details card */}
                    <div className="service-details-card">
                      <div className="thumbnail">
                        <img
                          src="assets/images/service/icon/11.svg"
                          alt=""
                          className="icon"
                        />
                      </div>
                      <div className="details">
                        <h6 className="title">Continuous Staff Development</h6>
                        <p className="disc">
                          Ensure that team members have the necessary skills to
                          reap maximum performance benefits.
                        </p>
                      </div>
                    </div>
                    {/* single service details card End */}
                  </div>
                  <div className="col-lg-6">
                    {/* single service details card */}
                    <div className="service-details-card">
                      <div className="thumbnail">
                        <img
                          src="assets/images/service/icon/12.svg"
                          alt=""
                          className="icon"
                        />
                      </div>
                      <div className="details">
                        <h6 className="title">Employee Retention Solutions</h6>
                        <p className="disc">
                          Maintain lower turnover rates by enhancing staff
                          satisfaction and loyalty via constant monitoring.
                        </p>
                      </div>
                    </div>
                    {/* single service details card End */}
                  </div>
                </div>
              </div>
              <div className="service-detials-step-2 mt--40">
                <h4 className="title">3 Simple Steps to Process</h4>
                <p className="disc mb--25">
                  Recruitment, hiring, HR &IS, employee evaluation – delegate
                  these skills to professionals. Your company’s productivity
                  will increase. Adjust and transform business processes and IT
                  systems effectively, providing for business integration
                </p>

                {/* stem-area start */}
                <div className="row  mb--40 g-5 mb_md--20 mb_sm--20">
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="single-service-step text-center">
                      <p className="step">01</p>
                      <h6 className="title">STEP ONE</h6>
                      <p className="disc">Job Requirement Analysis</p>
                      <p className="disc">
                        Determine role objectives, responsibilities, and
                        expectations. Identify required technical competencies,
                        soft skills, and qualifications
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="single-service-step text-center">
                      <p className="step">02</p>
                      <h6 className="title">STEP TWO</h6>
                      <p className="disc">JD Development and Compensation</p>
                      <p className="disc">
                        Draft a comprehensive job description outlining
                        responsibilities, skills, and qualifications
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="single-service-step text-center">
                      <p className="step">03</p>
                      <h6 className="title">STEP THREE</h6>
                      <p className="disc">
                        Resume Screening and Interview Process
                      </p>
                      <p className="disc">
                        Initial screening to shortlist candidates based on
                        skills and experience. Schedule required interview
                        rounds
                      </p>
                    </div>
                  </div>
                </div>
                <p className="disc">
                  Transform your recruitment processes with our cost-effective,
                  long-term recruitment strategies and capabilities. Allow us to
                  help you in the recruiting efforts, building efficient teams,
                  and achieving growth that would last.
                </p>
                {/* stem-area End */}
              </div>
              {/* service details left area end */}
              <div className="service-detials-step-3 mt--70 mt_md--50">
                <div className="row g-5 align-items-center">
                  <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="thumbnail sm-thumb-service">
                      <img
                        src="assets/images/service/sm-01.jpg"
                        alt="Service"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 mb_md--20 mb_sm--20">
                    <h4 className="title">Customer Benefits</h4>
                    <p className="disc">
                      Catalysts for change before fully tested markets are
                      maintain wireless scenarios after intermandated
                      applications predominate revolutionary.
                    </p>
                    <div className="single-banifits">
                      <i className="far fa-check-circle" />
                      <span>We use the latest diagnostic equipment</span>
                    </div>
                    <div className="single-banifits">
                      <i className="far fa-check-circle" />
                      <span>We are a member of Professional Service</span>
                    </div>
                    <div className="single-banifits">
                      <i className="far fa-check-circle" />
                      <span>Automotive service our clients receive</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*rts blog wizered area */}
            <div className="col-xl-4 col-md-12 col-sm-12 col-12 mt_lg--60 pl--50 pl_md--0 pl-lg-controler pl_sm--0">
              {/* single wizered start */}
              <div className="rts-single-wized Categories service">
                <div className="wized-header">
                  <h5 className="title">Categories</h5>
                </div>
                <div className="wized-body">
                  {/* single categoris */}
                  <ul className="single-categories">
                    <li>
                      <Link to={"#"}>
                        Permanent Staffing{" "}
                        <i className="far fa-long-arrow-right" />
                      </Link>
                    </li>
                  </ul>
                  {/* single categoris End */}
                  {/* single categoris */}
                  <ul className="single-categories">
                    <li>
                      <Link to={"#"}>
                        Temporary Staffing (Contract Staffing){" "}
                        <i className="far fa-long-arrow-right" />
                      </Link>
                    </li>
                  </ul>
                  {/* single categoris End */}
                  {/* single categoris */}
                  <ul className="single-categories">
                    <li>
                      <Link to={"#"}>
                        Contract-to-Hire Staffing{" "}
                        <i className="far fa-long-arrow-right" />
                      </Link>
                    </li>
                  </ul>
                  {/* single categoris End */}
                  {/* single categoris */}
                  <ul className="single-categories">
                    <li>
                      <Link to={"#"}>
                        Executive Search (Leadership Hiring){" "}
                        <i className="far fa-long-arrow-right" />
                      </Link>
                    </li>
                  </ul>
                  {/* single categoris End */}
                  {/* single categoris */}
                  <ul className="single-categories">
                    <li>
                      <Link to={"#"}>
                        Bulk Hiring (Mass Recruitment){" "}
                        <i className="far fa-long-arrow-right" />
                      </Link>
                    </li>
                  </ul>
                  {/* single categoris End */}
                </div>
              </div>
              {/* single wizered End */}

              {/* single wizered start */}
              <div className="rts-single-wized contact service">
                <div className="wized-header">
                  <Link to={"#"}>
                    <img
                      src="assets/images/logo/Verdeshell_Logo_white.png"
                      alt="Business_logo"
                    />
                  </Link>
                </div>
                <div className="wized-body">
                  <h5 className="title">Need Help? We Are Here To Help You</h5>
                  <Link className="rts-btn btn-primary" to={"/contactus"}>
                    Contact Us
                  </Link>
                </div>
              </div>
              {/* single wizered End */}
            </div>
            {/* rts- blog wizered end area */}
          </div>
        </div>
      </div>
      {/* End service details area */}

      <FooterOne />
    </div>
  );
}

export default HRStaffing;
