import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

function SideMenu({ isSidebarOpen, toggleSidebar }) {
  const [openMenu, setOpenMenu] = useState(null);

  const toggleMenu = (menuhome) => {
    setOpenMenu(openMenu === menuhome ? null : menuhome);
  };

  // useEffect(() => {
  //     const sidebar = document.getElementById('side-bar');
  //     const anywhereHome = document.getElementById('anywhere-home');

  //     if (isSidebarOpen) {
  //         sidebar.classList.add('show');
  //         anywhereHome.classList.add('bgshow');
  //     } else {
  //         sidebar.classList.remove('show');
  //         anywhereHome.classList.remove('bgshow');
  //     }
  // }, [isSidebarOpen]);

  return (
    <div>
      <div id="side-bar" className={`side-bar ${isSidebarOpen ? "show" : ""}`}>
        <button
          className="close-icon-menu"
          aria-label="Close Menu"
          onClick={toggleSidebar}
        >
          <i className="far fa-times"></i>
        </button>
        {/* inner menu area desktop start */}
        <div className="rts-sidebar-menu-desktop">
          <Link className="logo-1" href="index.html">
            <img
              className="logo"
              src="/assets/images/logo//verdeshell_logo1.svg"
              alt="verdeshell"
              width={150}
              height={75}
            />
          </Link>
          <Link className="logo-2" href="index.html">
            <img
              className="logo"
              src="/assets/images/logo/verdeshell_logo1.svg"
              alt="verdeshell"
              width={150}
              height={75}
            />
          </Link>
          <Link className="logo-3" href="index.html">
            <img
              className="logo"
              src="/assets/images/logo/verdeshell_logo1.svg"
              alt="verdeshell"
              width={150}
              height={75}
            />
          </Link>
          <Link className="logo-4" href="index.html">
            <img
              className="logo"
              src="/assets/images/logo/verdeshell_logo1.svg"
              alt="verdeshell"
              width={150}
              height={75}
            />
          </Link>
          <div className="body d-none d-xl-block">
            <p className="disc">
              your trusted partner for innovative IT solutions & Digital
              business expansion.
            </p>
            <div className="get-in-touch">
              {/* title */}
              <div className="h6 title">Get In Touch</div>
              {/* title End */}
              <div className="wrapper">
                {/* single */}
                <div className="single">
                  <i className="fas fa-phone-alt" />
                  <Link href="#">+91 9289355575</Link>
                </div>
                {/* single ENd */}
                {/* single */}
                <div className="single">
                  <i className="fas fa-envelope" />
                  <Link href="#">connect@verdeshell.com</Link>
                </div>
                <div className="single">
                  <i className="fas fa-envelope" />
                  <Link href="#">hiring@verdeshell.com</Link>
                </div>
                {/* single ENd */}
                {/* single */}
                <div className="single">
                  <i className="fas fa-globe" />
                  <Link href="#">www.verdeshell.com</Link>
                </div>
                {/* single ENd */}
                {/* single */}
                <div className="single">
                  <i className="fas fa-map-marker-alt" />
                  <Link href="#">Sec 58, Gurgaon, Haryana</Link>
                </div>
                {/* single ENd */}
              </div>
            </div>
          </div>
          <div className="body-mobile d-block d-xl-none">
            <nav className="nav-main mainmenu-nav">
              <ul className="mainmenu metismenu" id="mobile-menu-active">
                <li className="menu-item">
                  <Link href="/" onClick={() => toggleMenu(1)}>
                    Home
                  </Link>
                </li>
                <li className="menu-item">
                  <Link onClick={() => toggleMenu(2)} to={"/our-service"}>
                    Our Services
                  </Link>
                </li>{" "}
                <li className="menu-item">
                  <Link onClick={() => toggleMenu(3)} to={"/about-us"}>
                    Who we are!
                  </Link>
                </li>
                <li className="menu-item">
                  <Link onClick={() => toggleMenu(4)} to={"/career"}>
                    Careers
                  </Link>
                </li>
                <li className="menu-item">
                  <Link onClick={() => toggleMenu(5)} to={"/contactus"}>
                    Contact Us
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        {/* inner menu area desktop End */}
      </div>
    </div>
  );
}

export default SideMenu;
