import React from "react";
import Accordion from "react-bootstrap/Accordion";

function FaqTwo() {
  return (
    <div>
      {/* service accordion area */}
      <div className="rts-accordion-area service rts-section-gap">
        <div className="accordion-service-bg bg_image ptb--120 ptb_md--80 ptb_sm--60">
          <div className="container">
            <div className="row">
              <div className="col-xl-6">
                <div className="accordion-service-inner">
                  <div className="title-area-start">
                    <span className="sub color-primary">
                      JUST A CONSULTANCY
                    </span>
                    <h2 className="title">
                      We know how to manage business globally
                    </h2>
                  </div>
                  <div className="accordion-area">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          Making Easy Business Growth
                        </Accordion.Header>
                        <Accordion.Body>
                          Accelerate business growth by enabling scalable
                          infrastructure, technical strategy and innovation
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          Consultancy and Advise
                        </Accordion.Header>
                        <Accordion.Body>
                          Our strategic team-building helps align your
                          technology initiatives with your business objectives
                          appropriately
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          Strategy and Planning
                        </Accordion.Header>
                        <Accordion.Body>
                          We plan and implement the most suitable technologies
                          according to your project scope, timelines, and
                          lifecycle and frame KPIs to assess technological
                          performance
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header>Management Process</Accordion.Header>
                        <Accordion.Body>
                          • Requirement Analysis : We help in crafting the ideal
                          skill requirements, visualizing your plans and
                          evaluating your company’s needs. <br />
                          <br />• Project Management : We help in strategizing
                          an explicit project model designed to deliver value to
                          the organization <br />
                          <br />• Product Engineering : We fabricate technology
                          strategies fit for your business needs and budget that
                          take your company to new heights
                          <br />
                          <br />• Delivery : Our business strategy focuses on
                          effective team coordination and timely delivery of the
                          projects.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4">
                        <Accordion.Header>Business Expansion</Accordion.Header>
                        <Accordion.Body>
                          • Reduced Risk : Reduced operational risks, software
                          bloat, gained resiliency and improved risk mitigation
                          strategy <br />• Cost Optimization : Fulfill your
                          business goals and deliver immediate value with more
                          cost-efficient tech strategies
                          <br /> • IT Support : Provide support to your IT
                          initiatives, outline tech strategies and manage
                          technology infrastructure <br />• Dedicated Expertise
                          : Transform the as per industry market trends with
                          long-term financial planning
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* service accordion area End */}
    </div>
  );
}

export default FaqTwo;
