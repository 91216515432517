import React from "react";
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "./Breadcrumb";
import { Link } from "react-router-dom";

import FooterOne from "../components/footer/FooterOne";

function ITServices() {
  const breadcrumbs = [{ label: "Home", link: "/" }, { label: "HR Staffing" }];
  return (
    <div className="">
      <HeaderOne />
      <Breadcrumb title="IT Services" breadcrumbs={breadcrumbs} />

      {/* start service details area */}
      <div className="rts-service-details-area rts-section-gap">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-md-12 col-sm-12 col-12">
              {/* service details left area start */}
              <div className="service-detials-step-1">
                <div className="thumbnail">
                  <img src="assets/images/service/01.jpg" alt="business-area" />
                </div>
                <h4 className="title">IT Services</h4>
                <p className="disc">
                  For any business to operate, enhance productivity, and grow in
                  the cut-throat global market, it needs strong IT services to
                  handle its core business. We offer IT services that are
                  relevant to the business workflows across various verticals
                  such as infrastructure management, cloud solutions, network
                  solutions, and even cyber security services. Additionally, we
                  provide custom IT consulting services designed to meet the
                  needs and challenges of bringing your business’s operations up
                  to date.
                </p>
                <h6 className="title">
                  Proficiency in various Technology Stacks
                </h6>

                <div className="row g-5 mt--30 mb--40">
                  <div className="col-lg-6">
                    {/* single service details card */}
                    <div className="service-details-card">
                      <div className="thumbnail">
                        <img
                          src="assets/images/service/icon/09.svg"
                          alt=""
                          className="icon"
                        />
                      </div>
                      <div className="details">
                        <h6 className="title">Custom Cloud Solutions</h6>
                        <p className="disc">AWS, Azure and Google Cloud</p>
                      </div>
                    </div>
                    {/* single service details card End */}
                  </div>
                  <div className="col-lg-6">
                    {/* single service details card */}
                    <div className="service-details-card">
                      <div className="thumbnail">
                        <img
                          src="assets/images/service/icon/10.svg"
                          alt=""
                          className="icon"
                        />
                      </div>
                      <div className="details">
                        <h6 className="title">Full Stack Development</h6>
                        <p className="disc">
                          React, Angular, Node.js, .NET and Java
                        </p>
                      </div>
                    </div>
                    {/* single service details card End */}
                  </div>
                  <div className="col-lg-6">
                    {/* single service details card */}
                    <div className="service-details-card">
                      <div className="thumbnail">
                        <img
                          src="assets/images/service/icon/11.svg"
                          alt=""
                          className="icon"
                        />
                      </div>
                      <div className="details">
                        <h6 className="title">Mobile Applications</h6>
                        <p className="disc">
                          iOS, Android, React Native, Flutter
                        </p>
                      </div>
                    </div>
                    {/* single service details card End */}
                  </div>
                  <div className="col-lg-6">
                    {/* single service details card */}
                    <div className="service-details-card">
                      <div className="thumbnail">
                        <img
                          src="assets/images/service/icon/12.svg"
                          alt=""
                          className="icon"
                        />
                      </div>
                      <div className="details">
                        <h6 className="title">
                          Artificial Intelligence and ML
                        </h6>
                        <p className="disc">Data experts and AI specialists</p>
                      </div>
                    </div>
                    {/* single service details card End */}
                  </div>
                  <div className="col-lg-6">
                    {/* single service details card */}
                    <div className="service-details-card">
                      <div className="thumbnail">
                        <img
                          src="assets/images/service/icon/12.svg"
                          alt=""
                          className="icon"
                        />
                      </div>
                      <div className="details">
                        <h6 className="title">Database and applications</h6>
                        <p className="disc">
                          Oracle DBA, PL/SQL, SQL, Mongo DB, MySQL, PostgreSQL
                        </p>
                      </div>
                    </div>
                    {/* single service details card End */}
                  </div>
                  <div className="col-lg-6">
                    {/* single service details card */}
                    <div className="service-details-card">
                      <div className="thumbnail">
                        <img
                          src="assets/images/service/icon/12.svg"
                          alt=""
                          className="icon"
                        />
                      </div>
                      <div className="details">
                        <h6 className="title">DevOps</h6>
                        <p className="disc">
                          CI/CD processes, Kubernetes, and Jenkins
                        </p>
                      </div>
                    </div>
                    {/* single service details card End */}
                  </div>
                </div>
              </div>
              <div className="service-detials-step-2 mt--40">
                <h4 className="title">3 Simple Steps to Process</h4>
                <p className="disc mb--25">
                  1. Strategic Outline: Create and devise several years’ worth
                  of the broad newspaper outlines in order for IT strategies to
                  match the predetermined business goals. <br />
                  2. Efficiency Improvements: Determine restrictions or
                  bottlenecks, and find ways to improve them. <br />
                  3. Convergence of New Technology: Complete incorporation of
                  other new technologies into what already exists.
                </p>

                {/* stem-area start */}
                <div className="row  mb--40 g-5 mb_md--20 mb_sm--20">
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="single-service-step text-center">
                      <p className="step">01</p>
                      <h6 className="title">STEP ONE</h6>
                      <p className="disc">Identify Business requirements</p>
                      <p className="disc">
                        We commence our work by taking a complete assessment of
                        your IT structure in conjunction with what your business
                        aims to achieve
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="single-service-step text-center">
                      <p className="step">02</p>
                      <h6 className="title">STEP TWO</h6>
                      <p className="disc">Implement Customized Strategies</p>
                      <p className="disc">
                        Most effective tools, plans and strategies that help us
                        tailor solutions to the requirements of your business
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="single-service-step text-center">
                      <p className="step">03</p>
                      <h6 className="title">STEP THREE</h6>
                      <p className="disc">Continuous Monitoring</p>
                      <p className="disc">
                        Conduct periodic assessment and evaluation as well as
                        offer additional support in order to maintain required
                        operating conditions
                      </p>
                    </div>
                  </div>
                </div>
                <p className="disc">
                  Accelerate business growth by enabling scalable
                  infrastructure, technical strategy and innovation
                </p>
                {/* stem-area End */}
              </div>
              {/* service details left area end */}
              <div className="service-detials-step-3 mt--70 mt_md--50">
                <div className="row g-5 align-items-center">
                  <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="thumbnail sm-thumb-service">
                      <img
                        src="assets/images/service/sm-01.jpg"
                        alt="Service"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 mb_md--20 mb_sm--20">
                    <h4 className="title">Benefits of Custom IT Consulting</h4>
                    <p className="disc">
                      Eliminate additional costs with our fully-fledged IT
                      services that’ll provide added security and better control
                      over access aiding your business in transition to more
                      efficient systems.
                    </p>
                    <div className="single-banifits">
                      <i className="far fa-check-circle" />
                      <span>Requirement Analysis</span>
                    </div>
                    <div className="single-banifits">
                      <i className="far fa-check-circle" />
                      <span>Project Management</span>
                    </div>
                    <div className="single-banifits">
                      <i className="far fa-check-circle" />
                      <span>Prodcut Engineering</span>
                    </div>
                    <div className="single-banifits">
                      <i className="far fa-check-circle" />
                      <span>Continous Integration and Delivery</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*rts blog wizered area */}
            <div className="col-xl-4 col-md-12 col-sm-12 col-12 mt_lg--60 pl--50 pl_md--0 pl-lg-controler pl_sm--0">
              {/* single wizered start */}
              <div className="rts-single-wized Categories service">
                <div className="wized-header">
                  <h5 className="title">Categories</h5>
                </div>
                <div className="wized-body">
                  {/* single categoris */}
                  <ul className="single-categories">
                    <li>
                      <Link to={"#"}>
                        Infrastructure Management{" "}
                        <i className="far fa-long-arrow-right" />
                      </Link>
                    </li>
                  </ul>
                  {/* single categoris End */}
                  {/* single categoris */}
                  <ul className="single-categories">
                    <li>
                      <Link to={"#"}>
                        Cloud Computing Services{" "}
                        <i className="far fa-long-arrow-right" />
                      </Link>
                    </li>
                  </ul>
                  {/* single categoris End */}
                  {/* single categoris */}
                  <ul className="single-categories">
                    <li>
                      <Link to={"#"}>
                        Network Solutions{" "}
                        <i className="far fa-long-arrow-right" />
                      </Link>
                    </li>
                  </ul>
                  {/* single categoris End */}
                  {/* single categoris */}
                  <ul className="single-categories">
                    <li>
                      <Link to={"#"}>
                        Web & mobile App Development{" "}
                        <i className="far fa-long-arrow-right" />
                      </Link>
                    </li>
                  </ul>
                  {/* single categoris End */}
                  {/* single categoris */}
                  <ul className="single-categories">
                    <li>
                      <Link to={"#"}>
                        Cyber Security Services{" "}
                        <i className="far fa-long-arrow-right" />
                      </Link>
                    </li>
                  </ul>
                  {/* single categoris End */}
                </div>
              </div>
              {/* single wizered End */}

              {/* single wizered start */}
              <div className="rts-single-wized contact service">
                <div className="wized-header">
                  <Link to={"#"}>
                    <img
                      src="assets/images/logo/Verdeshell_Logo_white.png"
                      alt="Business_logo"
                    />
                  </Link>
                </div>
                <div className="wized-body">
                  <h5 className="title">Need Help? We Are Here To Help You</h5>
                  <Link className="rts-btn btn-primary" to={"/contactus"}>
                    Contact Us
                  </Link>
                </div>
              </div>
              {/* single wizered End */}
            </div>
            {/* rts- blog wizered end area */}
          </div>
        </div>
      </div>
      {/* End service details area */}

      <FooterOne />
    </div>
  );
}

export default ITServices;
