import React from "react";
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "./Breadcrumb";
import { Link } from "react-router-dom";

import FooterOne from "../components/footer/FooterOne";

function ITResourceManagement() {
  const breadcrumbs = [{ label: "Home", link: "/" }, { label: "HR Staffing" }];
  return (
    <div className="">
      <HeaderOne />
      <Breadcrumb title="IT Resource Management" breadcrumbs={breadcrumbs} />

      {/* start service details area */}
      <div className="rts-service-details-area rts-section-gap">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-md-12 col-sm-12 col-12">
              {/* service details left area start */}
              <div className="service-detials-step-1">
                <div className="thumbnail">
                  <img src="assets/images/service/01.jpg" alt="business-area" />
                </div>
                <h4 className="title">Our IT Staffing Services</h4>
                <p className="disc">
                  In the context of rapid technological development, the ability
                  to hire and retain IT personnel is of great importance for
                  business performance. We offer a whole lot of IT staffing
                  answers that might be tailored to fit the requirements of
                  companies of various kinds. Whether you need full-time
                  employees, settlement workers, or project-primarily based
                  placements, we have you blanketed. We will take care of the
                  details and have a seamless process of getting the right
                  candidate for the position.
                </p>
                <p className="disc">
                  HR perceptive processes using professional tools and models in
                  a non-traditional style. Present fully functional application
                  systems. Streamline your HR processes to ensure competitive
                  advantage
                </p>
                <div className="row g-5 mt--30 mb--40">
                  <div className="col-lg-6">
                    {/* single service details card */}
                    <div className="service-details-card">
                      <div className="thumbnail">
                        <img
                          src="assets/images/service/icon/09.svg"
                          alt=""
                          className="icon"
                        />
                      </div>
                      <div className="details">
                        <h6 className="title">Seamless Recruitment Process</h6>
                        <p className="disc">
                          Utilize prioritized recruitment models to meet
                          workplace demands more effectively.
                        </p>
                      </div>
                    </div>
                    {/* single service details card End */}
                  </div>
                  <div className="col-lg-6">
                    {/* single service details card */}
                    <div className="service-details-card">
                      <div className="thumbnail">
                        <img
                          src="assets/images/service/icon/10.svg"
                          alt=""
                          className="icon"
                        />
                      </div>
                      <div className="details">
                        <h6 className="title">Full-Time Placements</h6>
                        <p className="disc">
                          Includes hiring junior developers to senior
                          architects, all of whom would become invaluable assets
                          to the business.
                        </p>
                      </div>
                    </div>
                    {/* single service details card End */}
                  </div>
                  <div className="col-lg-6">
                    {/* single service details card */}
                    <div className="service-details-card">
                      <div className="thumbnail">
                        <img
                          src="assets/images/service/icon/11.svg"
                          alt=""
                          className="icon"
                        />
                      </div>
                      <div className="details">
                        <h6 className="title">Contract-Based Hiring</h6>
                        <p className="disc">
                          It allows for hiring talented IT workers for a
                          specified time without having to hire them on a
                          permanent basis
                        </p>
                      </div>
                    </div>
                    {/* single service details card End */}
                  </div>
                  <div className="col-lg-6">
                    {/* single service details card */}
                    <div className="service-details-card">
                      <div className="thumbnail">
                        <img
                          src="assets/images/service/icon/12.svg"
                          alt=""
                          className="icon"
                        />
                      </div>
                      <div className="details">
                        <h6 className="title">Project-Based Placements</h6>
                        <p className="disc">
                          We also offer IT professionals with highly specific
                          skill sets, In case your organization has specialized
                          requirements
                        </p>
                      </div>
                    </div>
                    {/* single service details card End */}
                  </div>
                </div>
              </div>
              <div className="service-detials-step-2 mt--40">
                <h4 className="title">3 Simple Steps to Process</h4>
                <p className="disc mb--25">
                  We have a good grasp of how IT roles change and we know how to
                  source requisite talent across several technology stacks and
                  skills
                </p>

                {/* stem-area start */}
                <div className="row  mb--40 g-5 mb_md--20 mb_sm--20">
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="single-service-step text-center">
                      <p className="step">01</p>
                      <h6 className="title">STEP ONE</h6>
                      <p className="disc">A List of Everything You Need</p>
                      <p className="disc">
                        We clearly look closely at your technical needs, your
                        purposes of the project and the type of organization.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="single-service-step text-center">
                      <p className="step">02</p>
                      <h6 className="title">STEP TWO</h6>
                      <p className="disc">Search for the Ideal Candidate</p>
                      <p className="disc">
                        We help you track and evaluate candidates through
                        creative sourcing methods that fit your threshold
                        specifications.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="single-service-step text-center">
                      <p className="step">03</p>
                      <h6 className="title">STEP THREE</h6>
                      <p className="disc">Continuous Assistance</p>
                      <p className="disc">
                        We furnish the necessary post-placement services that
                        are needed to facilitate successful adjustment and
                        management.
                      </p>
                    </div>
                  </div>
                </div>
                <p className="disc">
                  We have a better solution for your IT staffing needs be it in
                  a project or when seeking to put in place an IT staffing
                  strategy there’s no catching up with us. Allow us to assist
                  you in constructing a strong and flexible IT staff capable of
                  addressing current difficulties and determining future
                  success.
                </p>
                {/* stem-area End */}
              </div>
              {/* service details left area end */}
              <div className="service-detials-step-3 mt--70 mt_md--50">
                <div className="row g-5 align-items-center">
                  <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="thumbnail sm-thumb-service">
                      <img
                        src="assets/images/service/sm-01.jpg"
                        alt="Service"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 mb_md--20 mb_sm--20">
                    <h4 className="title">
                      Benefits of Utilizing Our IT Recruitment Services
                    </h4>
                    <p className="disc">
                      We are seasoned professionals in IT recruitment and we
                      know the dynamics of the sector and personnel’s appetite.
                    </p>
                    <div className="single-banifits">
                      <i className="far fa-check-circle" />
                      <span>Reduced Time-to-Hire</span>
                    </div>
                    <div className="single-banifits">
                      <i className="far fa-check-circle" />
                      <span>Enhanced Scalability</span>
                    </div>
                    <div className="single-banifits">
                      <i className="far fa-check-circle" />
                      <span>Flexible Engagement Model</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*rts blog wizered area */}
            <div className="col-xl-4 col-md-12 col-sm-12 col-12 mt_lg--60 pl--50 pl_md--0 pl-lg-controler pl_sm--0">
              {/* single wizered start */}
              <div className="rts-single-wized Categories service">
                <div className="wized-header">
                  <h5 className="title">Categories</h5>
                </div>
                <div className="wized-body">
                  {/* single categoris */}
                  <ul className="single-categories">
                    <li>
                      <Link to={"#"}>
                        Permanent Staffing{" "}
                        <i className="far fa-long-arrow-right" />
                      </Link>
                    </li>
                  </ul>
                  {/* single categoris End */}
                  {/* single categoris */}
                  <ul className="single-categories">
                    <li>
                      <Link to={"#"}>
                        Temporary Staffing (Contract Staffing){" "}
                        <i className="far fa-long-arrow-right" />
                      </Link>
                    </li>
                  </ul>
                  {/* single categoris End */}
                  {/* single categoris */}
                  <ul className="single-categories">
                    <li>
                      <Link to={"#"}>
                        Contract-to-Hire Staffing{" "}
                        <i className="far fa-long-arrow-right" />
                      </Link>
                    </li>
                  </ul>
                  {/* single categoris End */}
                  {/* single categoris */}
                  <ul className="single-categories">
                    <li>
                      <Link to={"#"}>
                        Executive Search (Leadership Hiring){" "}
                        <i className="far fa-long-arrow-right" />
                      </Link>
                    </li>
                  </ul>
                  {/* single categoris End */}
                  {/* single categoris */}
                  <ul className="single-categories">
                    <li>
                      <Link to={"#"}>
                        Bulk Hiring (Mass Recruitment){" "}
                        <i className="far fa-long-arrow-right" />
                      </Link>
                    </li>
                  </ul>
                  {/* single categoris End */}
                </div>
              </div>
              {/* single wizered End */}

              {/* single wizered start */}
              <div className="rts-single-wized contact service">
                <div className="wized-header">
                  <Link to={"#"}>
                    <img
                      src="assets/images/logo/Verdeshell_Logo_white.png"
                      alt="Business_logo"
                    />
                  </Link>
                </div>
                <div className="wized-body">
                  <h5 className="title">Need Help? We Are Here To Help You</h5>
                  <Link className="rts-btn btn-primary" to={"/contactus"}>
                    Contact Us
                  </Link>
                </div>
              </div>
              {/* single wizered End */}
            </div>
            {/* rts- blog wizered end area */}
          </div>
        </div>
      </div>
      {/* End service details area */}

      <FooterOne />
    </div>
  );
}

export default ITResourceManagement;
