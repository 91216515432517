import React from "react";
import { Link } from "react-router-dom";
function Nav() {
  return (
    <div>
      <nav className="nav-main mainmenu-nav d-none d-xl-block">
        <ul className="mainmenu">
          <li className="nav-item">
            <Link to={"/"}>Home</Link>
          </li>
          <li>
            <Link to={"/our-service"}>Our Services</Link>
          </li>
          <li className="nav-item">
            <Link to={"/about-us"}>Who we are!</Link>
          </li>
          <li>
            <Link className="nav-item" to={"/career"}>
              Careers
            </Link>
          </li>
          <li>
            <Link className="nav-item" to={"/contactus"}>
              Contact Us
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Nav;
